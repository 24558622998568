export const UpdateUser = `
  mutation updateAxiiUser($input: AxiiUserInput!) {
    updateAxiiUser(input: $input) {
      givenName
      familyName
      email
      addressStreet
      addressStreet2
      addressCountry
      addressPostalCode
      addressRegion
      addressLocality
    }
  }
`
