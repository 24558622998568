// flow
import React, { useState, useRef, useEffect, memo } from "react"
import Amplify, { Auth } from "aws-amplify"
import { RichText } from "prismic-reactjs"

import type { UpdatePersonalInfoPageType } from "../../types/UpdatePersonalInfoPageType"

import {
  PasswordTextbox,
  Button,
  linkResolver,
  FormValidator,
  awsconfig,
  ERRORS,
  htmlSerializerUpdateStyle,
  FORM_ERROR_NAME,
} from "@lesmills/gatsby-theme-common"

import AccountManagementModal from "../AccountManagementModal"
import { authStorage } from "@lesmills/gatsby-theme-common/src/services/amplifyStorage"

const { GATSBY_RT_26_07_2022_SYNC_SESSION } = process.env

type Props = {|
  handleDismiss?: () => void | Promise<void>,
  data: UpdatePersonalInfoPageType,
  newPassword: string,
  handleChangePasswordSuccess: () => void | Promise<void>,
  handleFormTrackingError: () => void,
|}

const CheckPreviousPassword = ({
  handleDismiss,
  data = {},
  newPassword,
  handleChangePasswordSuccess,
  handleFormTrackingError,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const previousPasswordRef = useRef(null)
  const [error, setError] = useState(null)

  const {
    forgot_password = {},
    previous_password_label = {},
    previous_password_subtitle = {},
    save_button_label = {},
    previous_password_title = {},
    previous_password_required = {},
    password_invalid = {},
  } = data

  useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      ...(GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && {
        Auth: {
          storage: authStorage,
        },
      }),
    })
  })

  const handleOnKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleChangePassword = async previousPassword => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, previousPassword, newPassword)
      setIsProcessing(false)

      handleDismiss()
      handleChangePasswordSuccess()
    } catch (err) {
      setIsProcessing(false)
      handleFormTrackingError(FORM_ERROR_NAME.SERVER_ERROR)
      setError(
        data[
          ERRORS.ChangePassword[err.code]
            ? ERRORS.ChangePassword[err.code]
            : "change_password_error"
        ].text
      )
    }
  }

  const handleSubmit = () => {
    // form validator
    const previousPassword = previousPasswordRef.current.value

    const err = FormValidator(["REQUIRED", "PASSWORD"], previousPassword, {
      requiredError: previous_password_required.text,
      invalidError: password_invalid.text,
    })
    handleFormTrackingError(FORM_ERROR_NAME.INCOMPELTE_FIELDS)
    setError(err)

    if (!err) {
      setIsProcessing(true)
      // call API
      handleChangePassword(previousPassword)
    }
  }

  return (
    <AccountManagementModal
      handleDismiss={handleDismiss}
      title={previous_password_title.text}
      classNames={{
        wrapper: "md:w-446 lg:w-590 mt-30",
        content:
          " animation-fade-in w-100vw md:w-fit-content md:w-moz-fit-content",
      }}
      testId="check-previous-password-modal"
    >
      <div data-cy="check-previous-password-modal-subtitle">
        <RichText
          render={previous_password_subtitle.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "text-base-light md:text-2lg md:leading-7none text-center text-gray-800 mt-20 mb-25"
          )}
        />
      </div>
      <PasswordTextbox
        textbox={{
          label: previous_password_label.text,
          classNames: {
            textbox: "w-full h-textbox-base md:h-textbox-lg md:text-2lg",
            label: "md:text-2lg",
            wrapper: "md:mt-25 mt-44 w-full mt-100 md:mt-0",
          },
          testId: "previous-password-input",
          id: "previous-password",
          disabled: isProcessing,
          inputRef: previousPasswordRef,
          error,
          handleOnKeyPress: handleOnKeyPress,
        }}
      />
      <div
        className="text-right text-base font-base-light text-gray-800 leading-tight"
        data-cy="forgot-password-link"
      >
        <RichText render={forgot_password.raw} linkResolver={linkResolver} />
      </div>
      <Button
        handleOnClick={() => handleSubmit()}
        disabled={isProcessing}
        submitting={isProcessing}
        className="btn btn-primary uppercase w-full py-3 mt-205 mb-30 md:mt-30"
        testId="save-previous-password-btn"
      >
        {save_button_label.text}
      </Button>
    </AccountManagementModal>
  )
}

export default memo(CheckPreviousPassword, (prevProps, nextProps) => {
  return prevProps.newPassword === nextProps.newPassword
})
