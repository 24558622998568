import React, { useState, useRef, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { API, graphqlOperation } from "aws-amplify"

import type { UpdatePersonalInfoPageType } from "../../types/UpdatePersonalInfoPageType"
import type { UserType } from "../../type/UserType"
import type { AllLayoutsType } from "@lesmills/gatsby-theme-common"

import {
  Textbox,
  Button,
  ROUTES,
  FormValidator,
  checkHaveErrors,
  handleErrorWithPrismic,
  isEmpty,
  isBrowser,
  captureException,
  SENTRY_ACTIONS,
  getCurrentUser,
  Spinner,
  useFormStartPushTracking,
  FORM_NAME,
  getPlanPeriod,
  formErrorTracking,
  FORM_ERROR_NAME,
  formSubmitTracking,
  customerDetailsUpdateTracking,
} from "@lesmills/gatsby-theme-common"

import Container from "../Container"
import InformationWrapper from "../InformationWrapper"
import CheckPreviousPassword from "../CheckPreviousPassword"
import { UpdateUser } from "../../graphql/updateUser"
import loadable from "@loadable/component"

const NotificationToast = loadable(
  () => import("@lesmills/gatsby-theme-common"),
  {
    fallback: <Spinner />,
    resolveComponent: components => components.NotificationToast,
  }
)

type Props = {|
  user: UserType,
  prismicData: UpdatePersonalInfoPageType,
  layoutData: AllLayoutsType,
  lang: string,
|}

const EditPersonalInformation = ({
  prismicData = {},
  lang,
  user = {},
}: Props) => {
  const [isShowedModal, toggleModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(true)
  const [responseStatus, setResponseStatus] = useState({})
  const [userData, setUser] = useState({})
  const [errors, setErrors] = useState({})
  const passwordRef = useRef(null)
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)
  const {
    change_password_label = {},
    change_password_title = {},
    save_button_label = {},
    password_invalid = {},
    password_required = {},
    title = {},
    body = {},
    first_name_required = {},
    last_name_required = {},
    email_required = {},
    email_invalid = {},
    update_personal_information_error = {},
    existing_email_error = {},
    update_personal_information_success = {},
    update_email_success = {},
    update_name_success = {},
    back_to_my_account = {},
    change_password_button_label = {},
  } = prismicData || {}
  const { items = [] } = body[0] || {}
  const [
    nameField = { label: {}, field_name: { raw: [] } },
    emailField = { label: {}, field_name: { raw: [] } },
  ] = items

  const { name: planName, tier, product_price_point } =
    user?.lmodSubscription?.product || {}

  const defaultTrackingData = {
    formName: FORM_NAME.EDIT_PERSONAL_DETAILS_FORM,
    planName: planName,
    planTier: tier?.internal_tier_id,
    planBillingFreq:
      product_price_point &&
      getPlanPeriod(
        product_price_point?.interval,
        product_price_point?.interval_unit
      ),
  }

  const handleFormSubmitTracking = () => {
    formSubmitTracking(defaultTrackingData)
    customerDetailsUpdateTracking(user?.vimeoUserId)
  }

  const handleFormTrackingError = formErrorName => {
    formErrorTracking({
      ...defaultTrackingData,
      formErrorName,
    })
  }

  const { handleFormStarted } = useFormStartPushTracking(defaultTrackingData)

  useEffect(() => {
    // Loading indicator should be showed until user was fetched
    if (user && !isEmpty(user)) {
      setUser(user)
      setIsProcessing(false)
    }
  }, [user])

  const getValidatorErrors = (firstName, lastName, email) => ({
    firstName: FormValidator(["REQUIRED"], firstName, {
      requiredError: first_name_required.text,
    }),
    lastName: FormValidator(["REQUIRED"], lastName, {
      requiredError: last_name_required.text,
    }),
    email: FormValidator(["REQUIRED", "EMAIL"], email, {
      requiredError: email_required.text,
      invalidError: email_invalid.text,
    }),
  })

  const handleOnKeyPress = (e, submitForm) => {
    if (e.which === 13) {
      e.preventDefault()
      submitForm()
    }
  }

  const getSuccessMessage = (firstName, lastName, newEmail) => {
    const { givenName, familyName, email } = userData
    const isChangeUserName = firstName !== givenName || lastName !== familyName
    const isChangeEmail = newEmail !== email

    if (isChangeUserName && isChangeEmail) {
      return update_personal_information_success.text
    }
    if (isChangeEmail) {
      return update_email_success.text
    }
    if (isChangeUserName) {
      return update_name_success.text
    }
  }

  const handleUpdateUser = async (firstName, lastName, newEmail) => {
    // Integrate with API
    try {
      const response = await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            givenName: firstName,
            familyName: lastName,
            email: newEmail.toLowerCase(),
          },
        })
      )

      setIsProcessing(false)
      if (response.errors && response.errors.length > 0) {
        handleErrorWithPrismic(
          response.errors,
          update_personal_information_error.text,
          setResponseStatus,
          prismicData,
          SENTRY_ACTIONS.UPDATE_USER,
          false, // isGetErrorCode
          {
            //requestVariables
            givenName: firstName,
            familyName: lastName,
            email: newEmail,
          }
        )
      } else {
        getCurrentUser(setUser) // Update current user for checking info when user update many times continuous

        handleFormSubmitTracking()
        setResponseStatus({
          message: getSuccessMessage(firstName, lastName, newEmail),
          type: "success",
        })
      }
    } catch (exceptionErr) {
      const { errorType = "" } = exceptionErr.errors
        ? exceptionErr.errors[0]
        : {}
      let errorMessage = update_personal_information_error.text

      setIsProcessing(false)
      // Show error message when duplicate with existing account
      if (errorType === "lmod_ucu_err") {
        errorMessage = existing_email_error.text
      }

      handleFormTrackingError(FORM_ERROR_NAME.SERVER_ERROR)

      setResponseStatus({
        message: errorMessage,
        type: "error",
      })

      if (exceptionErr.errors && exceptionErr.errors.length > 0) {
        captureException({
          action: SENTRY_ACTIONS.UPDATE_USER,
          requestVariables: {
            givenName: firstName,
            familyName: lastName,
            email: newEmail,
          },
          ...exceptionErr.errors[0],
        })
      }
    }
  }

  const handleChangePasswordSuccess = () => {
    setResponseStatus({
      message: update_personal_information_success.text,
      type: "success",
    })

    handleFormSubmitTracking()
    isBrowser && window.scrollTo(0, 0)
  }

  const handleSubmit = () => {
    const firstName = firstNameRef.current && firstNameRef.current.value.trim()
    const lastName = lastNameRef.current && lastNameRef.current.value.trim()
    const email = emailRef.current && emailRef.current.value.trim()

    const validatorErrors = getValidatorErrors(firstName, lastName, email)

    setErrors({
      ...errors,
      ...validatorErrors,
    })

    // if no error
    if (!checkHaveErrors(validatorErrors)) {
      // Only update user when input info is different with currentUser info
      if (
        firstName !== userData.givenName ||
        lastName !== userData.familyName ||
        email !== userData.email
      ) {
        setIsProcessing(true)
        handleUpdateUser(firstName, lastName, email)
      }
    } else {
      handleFormTrackingError(FORM_ERROR_NAME.INCOMPELTE_FIELDS)
    }
  }

  const getNewPassword = () => passwordRef.current.value

  const handleChangePassword = () => {
    const err = FormValidator(["REQUIRED", "PASSWORD"], getNewPassword(), {
      requiredError: password_required.text,
      invalidError: password_invalid.text,
    })

    setErrors({
      ...errors,
      password: err,
    })

    if (!err) {
      toggleModal(true)
    } else {
      handleFormTrackingError(FORM_ERROR_NAME.INCOMPELTE_FIELDS)
    }

    return false
  }

  const handleDisableLink = e => {
    if (isProcessing) {
      e.preventDefault()
    }
    return
  }

  return (
    <Container
      title={title.text}
      backLink={{
        name: back_to_my_account.text,
        url: ROUTES(lang).CUSTOMER_ACCOUNT,
      }}
      classNames={{ wrapper: " max-w-595" }}
      disabled={isProcessing}
      handleDisableLink={handleDisableLink}
    >
      {Object.keys(responseStatus).length > 0 && (
        <NotificationToast
          children={<p>{responseStatus.message}</p>}
          showHideIcon
          handleHideToast={() => setResponseStatus({})}
          type={responseStatus.type}
          classNames={{
            wrapper: " mt-20",
          }}
          testId="update-user-info-status"
        />
      )}
      <div className="w-full border-b border-gray-500 md:py-25 pt-10 pb-25">
        <InformationWrapper
          title={nameField.label.text}
          classNames={{
            wrapper: " mt-25",
          }}
          testId="name-wrapper"
        >
          <div className="flex flex-wrap justify-between">
            <Textbox
              label={
                nameField.field_name.raw[0] && nameField.field_name.raw[0].text
              }
              classNames={{
                textbox: "w-full h-textbox-base md:h-textbox-lg lg:text-2lg",
                label: "md:text-2lg",
                wrapper: "md:mt-25 mt-4 w-full lg:w-48/100",
              }}
              defaultValue={userData.givenName}
              id="first-name"
              disabled={isProcessing}
              inputRef={firstNameRef}
              error={errors.firstName}
              handleOnKeyPress={e => handleOnKeyPress(e, handleSubmit)}
              handleOnFocus={handleFormStarted}
              testId="first-name"
            />
            <Textbox
              label={
                nameField.field_name.raw[1] && nameField.field_name.raw[1].text
              }
              classNames={{
                textbox: "w-full h-textbox-base md:h-textbox-lg md:text-2lg",
                label: "md:text-2lg",
                wrapper: "md:mt-25 mt-4 w-full lg:w-48/100",
              }}
              defaultValue={userData.familyName}
              id="last-name"
              disabled={isProcessing}
              inputRef={lastNameRef}
              error={errors.lastName}
              handleOnKeyPress={e => handleOnKeyPress(e, handleSubmit)}
              testId="last-name"
            />
          </div>
        </InformationWrapper>
        <InformationWrapper
          title={emailField.label.text}
          testId="email-wrapper"
        >
          <Textbox
            label={emailField.field_name.text}
            defaultValue={user.email}
            classNames={{
              textbox: "w-full h-textbox-base md:h-textbox-lg md:text-2lg",
              label: "md:text-2lg",
              wrapper: "md:mt-25 mt-4 w-full",
            }}
            testId="email-input"
            id="email"
            disabled={isProcessing}
            error={errors.email}
            inputRef={emailRef}
          />
        </InformationWrapper>
        <Button
          handleOnClick={() => handleSubmit()}
          disabled={isProcessing}
          submitting={isProcessing}
          className="btn btn-primary-gray uppercase w-full py-3 md:mt-30 mt-10 md:mb-20"
          testId="save-btn"
        >
          {save_button_label.text}
        </Button>
      </div>
      <InformationWrapper
        title={change_password_title.text}
        classNames={{
          wrapper: "md:border-b-0 md:mt-50 mt-25",
        }}
        testId="password-wrapper"
      >
        <Textbox
          label={change_password_label.text}
          type="password"
          placeholder="**************************"
          defaultValue=""
          classNames={{
            textbox:
              "w-full h-textbox-base md:h-textbox-lg md:text-2lg change-password",
            label: "md:text-2lg",
            wrapper: "mt-25 md:mt-30 w-full",
          }}
          handleOnFocus={handleFormStarted}
          testId="password-input"
          id="password"
          disabled={isProcessing}
          inputRef={passwordRef}
          error={errors.password}
          handleOnKeyPress={e => handleOnKeyPress(e, handleChangePassword)}
        />
      </InformationWrapper>
      <Button
        handleOnClick={() => handleChangePassword()}
        className="btn btn-primary-gray uppercase w-full py-3 md:mt-30 mb-50"
        testId="change-password-btn"
      >
        {change_password_button_label.text}
      </Button>
      {isShowedModal && (
        <CheckPreviousPassword
          handleDismiss={() => toggleModal(false)}
          data={prismicData}
          newPassword={getNewPassword()}
          handleChangePasswordSuccess={handleChangePasswordSuccess}
          handleFormTrackingError={handleFormTrackingError}
        />
      )}
    </Container>
  )
}

export default memo(EditPersonalInformation, (prevProps, nextProps) => {
  return isEqual(prevProps.user, nextProps.user)
})
