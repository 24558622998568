import React from "react"
import { graphql } from "gatsby"
import type { UpdatePersonalInfoPageType } from "../types/UpdatePersonalInfoPageType"
import type { UserType, AllLayoutsType } from "@lesmills/gatsby-theme-common"
import {
  renderSEO,
  Layout,
  PrivateRoute,
  ROUTES,
} from "@lesmills/gatsby-theme-common"
import { withPreview } from "gatsby-source-prismic"

import EditPersonalInformation from "../components/EditPersonalInformation"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: {
    prismicUpdatePersonalInformationPage: UpdatePersonalInfoPageType,
    prismicLayout: AllLayoutsType,
  },
|}

const EditPersonalInformationPage = (props: Props) => {
  const { pageContext, data } = props || {}

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicUpdatePersonalInformationPage = {} } = data
  const pageContent = prismicUpdatePersonalInformationPage.data || {}
  const layout = prismicLayout.data || {}

  const renderEditPersonalInforPage = ({ user, checkingSession }: UserType) => (
    <Layout data={layout} lang={lang} user={user} isLoading={checkingSession}>
      {renderSEO(pageContent, ROUTES(lang).EDIT_PERSONAL_INFORMATION, lang)}
      <EditPersonalInformation
        prismicData={pageContent}
        layoutData={layout}
        lang={lang}
        user={user}
      />
    </Layout>
  )

  return (
    <PrivateRoute
      component={renderEditPersonalInforPage}
      {...props}
      lang={lang}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        logo {
          alt
          url
        }
        sign_in_button {
          raw
          text
        }
        sign_out_button {
          raw
          text
        }
        lmod_gu_err {
          text
        }
        body {
          ...LayoutBodyNavigation
        }
        body1 {
          ...LayoutBody1Navigation
        }
      }
    }
    prismicUpdatePersonalInformationPage(lang: { eq: $lang }) {
      data {
        back_to_my_account {
          raw
          text
        }
        body {
          ... on PrismicUpdatePersonalInformationPageBodyPersonalInformations {
            items {
              field_name {
                raw
                text
              }
              label {
                raw
                text
              }
            }
          }
        }
        change_password_button_label {
          raw
          text
        }
        change_password_error {
          raw
          text
        }
        change_password_label {
          raw
          text
        }
        change_password_title {
          raw
          text
        }
        email_invalid {
          raw
          text
        }
        email_required {
          raw
          text
        }
        existing_email_error {
          raw
          text
        }
        first_name_required {
          raw
          text
        }
        forgot_password {
          raw
          text
        }
        get_personal_information_error {
          raw
          text
        }
        invalid_password_exception {
          raw
          text
        }
        last_name_required {
          raw
          text
        }
        lmod_gu_err {
          raw
          text
        }
        lmod_sseg_err {
          raw
          text
        }
        lmod_ucdsu_err {
          raw
          text
        }
        lmod_uchc_err {
          raw
          text
        }
        lmod_uchchttp_err {
          raw
          text
        }
        lmod_ucu_err {
          raw
          text
        }
        name_required {
          raw
          text
        }
        not_authorized_exception {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        password_invalid {
          raw
          text
        }
        password_required {
          raw
          text
        }
        previous_password_label {
          raw
          text
        }
        previous_password_required {
          raw
          text
        }
        previous_password_subtitle {
          raw
          text
        }
        previous_password_title {
          raw
          text
        }
        save_button_label {
          raw
          text
        }
        title {
          raw
          text
        }
        update_email_success {
          text
          raw
        }
        update_name_success {
          raw
          text
        }
        update_personal_information_error {
          raw
          text
        }
        update_personal_information_success {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(EditPersonalInformationPage)
